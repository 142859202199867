import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import Config from '../constants/Config';

const getCurrentDomain = () => {
  // eslint-disable-next-line
  let currentHostName = window.location.hostname;
  if (currentHostName.includes('designesy')) {
    currentHostName = '.designesy.com';
  }

  if (currentHostName.includes('localhost')) {
    currentHostName = '.localhost';
  }

  return currentHostName;
};

export const setAccessToken = (accessToken) => {
  const { exp } = jwtDecode(accessToken);
  const expires = new Date(exp * 1000);

  const currentDomain = getCurrentDomain();

  Cookies.set(Config.ACCESS_TOKEN_KEY, accessToken, {
    expires,
    secure: window.location.protocol === 'https:',
    domain: currentDomain,
  });

  Cookies.set(Config.ACCESS_TOKEN_VERSION_KEY, Config.ACCESS_TOKEN_VERSION, {
    expires,
    domain: currentDomain,
  });
};

export const removeAccessToken = () => {
  console.log('remove access token');

  const currentDomain = getCurrentDomain();

  Cookies.remove(Config.ACCESS_TOKEN_KEY, {
    domain: currentDomain,
  });

  Cookies.remove(Config.ACCESS_TOKEN_VERSION_KEY, {
    domain: currentDomain,
  });
};

export const getAccessToken = () => {
  let accessToken = Cookies.get(Config.ACCESS_TOKEN_KEY);
  const accessTokenVersion = Cookies.get(Config.ACCESS_TOKEN_VERSION_KEY);

  if (accessToken && accessTokenVersion !== Config.ACCESS_TOKEN_VERSION) {
    removeAccessToken();
    accessToken = undefined;
  }

  return accessToken;
};
