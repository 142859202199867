export default {
  translation: {
    common: {
      emailOrUsername: 'E-mail or username',
      invalidEmailOrUsername: 'Invalid e-mail or username',
      invalidPassword: 'Invalid password',
      logInToPlanka: 'Designer login',
      notForClients: 'Not for clients.',
      noInternetConnection: 'No internet connection',
      pageNotFound_title: 'Page Not Found',
      password: 'Password',
      projectManagement: 'Subscription-based design services (UX/UI & branding).',
      serverConnectionFailed: 'Server connection failed',
      unknownError: 'Unknown error, try again later',
    },

    action: {
      logIn: 'Log in',
    },
  },
};
