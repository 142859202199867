import Config from './Config';

const ROOT = `${Config.BASE_PATH}/`;
const LOGIN = `${Config.BASE_PATH}/login`;
const PROJECTS = `${Config.BASE_PATH}/projects/:id`;
const BOARDS = `${Config.BASE_PATH}/boards/:id`;
const CARDS = `${Config.BASE_PATH}/cards/:id`;
const AUTHENTICATE_WITH_COOKIE = `${Config.BASE_PATH}/authenticate-with-cookie`;

export default {
  ROOT,
  LOGIN,
  PROJECTS,
  BOARDS,
  CARDS,
  AUTHENTICATE_WITH_COOKIE,
};
