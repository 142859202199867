import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './AuthenticateWithCookiePage.module.scss';
import { setAccessToken } from '../../utils/access-token-storage';

function AuthenticateWithCookiePage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const loginWithAuthenticationToken = () => {
    let finalPath = '/';
    const authenticationToken = searchParams.get('token');
    setAccessToken(authenticationToken);

    const board = searchParams.get('board');
    const boards = '/boards/';

    const card = searchParams.get('card');
    const cards = '/cards/';

    const boardsPath = boards + board;

    if (board) {
      // eslint-disable-next-line
      finalPath = boards + board;
    }

    if (card) {
      // eslint-disable-next-line
      finalPath = cards + card;
    }

    const url = window.location.origin + finalPath;
    window.location.replace(url);
  };

  useEffect(() => {
    loginWithAuthenticationToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Loader active size="massive" />
    </div>
  );
}

export default AuthenticateWithCookiePage;
